import {
  PRE_TITLE,
  PARAGRAPH,
  HERO_TITLE,
  CTA,
  LEGAL_TEXT,
  MEDIA_URL,
  MEDIA_FILE,
  PRE_TITLE_MAX_LENGTH,
  HERO_TITLE_MAX_LENGTH,
  CTA_MAX_LENGTH,
  PARAGRAPH_MAX_LENGTH,
  LEGAL_TEXT_MAX_LENGTH,
} from './constants';

export const validateHeroFields = (fields: any) => {
  let control: boolean = true;
  let errorMessage: string = '';

  const parseFields = JSON.parse(fields);

  if (
    (parseFields[MEDIA_URL]?.checked === undefined ||
      !parseFields[MEDIA_URL]?.checked) &&
    (!parseFields[MEDIA_FILE]?.checked ||
      parseFields[MEDIA_FILE]?.checked === undefined)
  ) {
    return {
      control: false,
      errorMessage: '[HERO] Media URL or Media File are required ',
    };
  }

  if (
    (!isValidUrl(parseFields[MEDIA_URL].desktopLarge) ||
      !isValidUrl(parseFields[MEDIA_URL].desktop) ||
      !isValidUrl(parseFields[MEDIA_URL].mobile)) &&
    parseFields[MEDIA_URL].checked
  ) {
    return {
      control: false,
      errorMessage: '[HERO] Media URL is required and should be a valid URL',
    };
  }

  if (
    parseFields[MEDIA_FILE].checked &&
    (!parseFields[MEDIA_FILE].desktopLarge ||
      !parseFields[MEDIA_FILE].desktop ||
      !parseFields[MEDIA_FILE].mobile ||
      !parseFields[MEDIA_FILE])
  ) {
    return {
      control: false,
      errorMessage: '[HERO] Media file is required',
    };
  }

  // if (
  //   !parseFields?.[URL_LEGAL_LINK] ||
  //   typeof parseFields[URL_LEGAL_LINK] !== 'string' ||
  //   !parseFields[URL_LEGAL_LINK].length
  // ) {
  //   return {
  //     control: false,
  //     errorMessage:
  //       '[HERO] URL Legal link is required and should be a valid URL',
  //   };
  // }

  if (parseFields?.enableBanner) {
    if (
      parseFields?.[PRE_TITLE] &&
      typeof parseFields[PRE_TITLE] === 'string' &&
      parseFields[PRE_TITLE].length > PRE_TITLE_MAX_LENGTH
    ) {
      return {
        control: false,
        errorMessage: `[HERO] Pre Title should be less than ${PRE_TITLE_MAX_LENGTH} characters`,
      };
    }

    if (
      typeof parseFields?.[HERO_TITLE] !== 'string' ||
      parseFields[HERO_TITLE].length > HERO_TITLE_MAX_LENGTH ||
      !parseFields[HERO_TITLE].length
    ) {
      return {
        control: false,
        errorMessage: `[HERO] Title is required and should be less than ${HERO_TITLE_MAX_LENGTH} characters`,
      };
    }

    if (
      typeof parseFields?.[CTA] !== 'string' ||
      parseFields[CTA].length > CTA_MAX_LENGTH ||
      !parseFields[CTA].length
    ) {
      return {
        control: false,
        errorMessage: `[HERO] CTA is required and should be less than ${CTA_MAX_LENGTH} characters`,
      };
    }

    if (
      parseFields?.[PARAGRAPH] &&
      typeof parseFields[PARAGRAPH] === 'string' &&
      parseFields[PARAGRAPH].length > PARAGRAPH_MAX_LENGTH
    ) {
      return {
        control: false,
        errorMessage: `[HERO] Paragraph should be less than ${PARAGRAPH_MAX_LENGTH} characters`,
      };
    }

    if (
      parseFields?.[LEGAL_TEXT] &&
      typeof parseFields[LEGAL_TEXT] === 'string' &&
      parseFields[LEGAL_TEXT].length > LEGAL_TEXT_MAX_LENGTH
    ) {
      return {
        control: false,
        errorMessage: `[HERO] Legal text should be less than ${LEGAL_TEXT_MAX_LENGTH} characters`,
      };
    }
  }

  return {
    control,
    errorMessage,
  };
};

export const isValidUrl = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch (_) {
    return false;
  }
};
