import { Switch } from 'antd';
import React, { createContext, FC, useContext, useEffect } from 'react';
import { HomeSectionList, HomeSectionListKey } from './WebeatHomeSectionEnum';
import { WebeatHomeSectionsConfig } from './WebeatHomeSectionsConfig';

import { RenderHeroFields } from './components';
import { heroHandler } from './components/hero/hero.handler';

import Text from 'antd/lib/typography/Text';

import * as api from '../../api'

interface WebeatHomeSectionContextType {
  combos: {
    colors: string[];
    buttons: string[];
  }
}

const WebeatHomeSectionContext = createContext<WebeatHomeSectionContextType | null>(null);

export const useWebeatHomeSectionContext = () => {
  const context = useContext(WebeatHomeSectionContext);
  if (!context) {
    throw new Error('useWebeatHomeSectionContext debe ser usado dentro de un WebeatHomeSectionProvider');
  }
  return context;
};


export const WebeatHomeSectionFields: FC<{
  sectionKey: HomeSectionListKey;
  handleChange: (
    sectionKey: HomeSectionListKey,
    active: boolean,
    content: string,
  ) => void;
  active: boolean;
  content: string;
  homeId?: string;
}> = ({ sectionKey, handleChange, active, content, homeId }) => {
  const homeSectionListItem = HomeSectionList[sectionKey];
  const [activeState, setActive] = React.useState(!!active);
  const [contentState, setContent] = React.useState(JSON.parse(content));
  const [colors, setColors] = React.useState<string[]>([]);
  const [buttons, setButtons] = React.useState<string[]>([]);

  useEffect(() => {
    console.log('useEffect')
    api.getDataCall({
      dataPath: '/design-resources/color',
      callConfig: {}
    }).then((response) => {
      console.log({data: response.data})
      setColors(response.data)
    });
    api.getDataCall({
      dataPath: '/design-resources/button',
      callConfig: {}
    }).then((response) => {
      console.log({data: response.data})
      setButtons(response.data)
    });
  }, [])


  const handleChangeActive = (value: boolean) => {
    setActive(value);
    handleChange(sectionKey, value, JSON.stringify(contentState));
  };

  const handleChangeContent = (key: string, value: any) => {
    heroHandler(key, contentState);

    setContent({
      ...contentState,
      [key]: value,
    });

    handleChange(
      sectionKey,
      activeState,
      JSON.stringify({
        ...contentState,
        [key]: value,
      }),
    );
  };

  return (
    <WebeatHomeSectionContext.Provider value={{
      combos: {
        colors,
        buttons
      }
    }}>
    <div className="webeat-home-sections__panel--content">
      <div
        style={{
          display: 'flex',
          gap: 10,
          alignItems: 'center',
        }}>
        <Switch
          checked={activeState}
          onChange={(e) => handleChangeActive(e)}
          disabled={homeSectionListItem.required}
        />
        <Text>Active</Text>
      </div>

      {sectionKey === 'Hero' &&
        WebeatHomeSectionsConfig['Hero'].fields.map((fieldConfig) => {
          const fieldValue = contentState[fieldConfig.key];
          return (
            <div key={fieldConfig.key}>
              <RenderHeroFields
                fieldConfig={fieldConfig}
                handleChange={handleChangeContent}
                value={fieldValue}
                contentState={contentState}
                homeId={homeId}
              />
            </div>
          );
        })}
    </div>
    </WebeatHomeSectionContext.Provider>
  );
};
